import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { APIProvider } from '@vis.gl/react-google-maps';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const gMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Suspense fallback={() => <div>"Loading..."</div>}>
		<APIProvider apiKey={gMapsApiKey}>
			<App />
		</APIProvider>
	</Suspense>
);

