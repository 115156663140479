import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

// import { useJsApiLoader } from '@react-google-maps/api';
import debounce from 'lodash/debounce';

import { createClient } from '@supabase/supabase-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';

import './AddressFormStyles.css'

const supabaseUrl = 'https://ikjlsgqudxijcepdkapu.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlramxzZ3F1ZHhpamNlcGRrYXB1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDA3NDc1MDEsImV4cCI6MjAxNjMyMzUwMX0.ffNRFReG0fNZh97dfrX1FzXLtdsT_mnqc2jtaesjxB8';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const initialState = {
	items: [],
	active: 0
}

// const googleLibraries = ['places'];

const AddressFormWithMap = () => {
    
	// const { isLoaded } = useJsApiLoader({
	// 	id: 'google-map-script',
	// 	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	// 	libraries: googleLibraries
	// })

    const [ isSubmitting, setIsSubmitting ] = useState(false);
	
	const [ services, setServices ] = useState({
		autocompleteService: new window.google.maps.places.AutocompleteService(),
		geocoder: new window.google.maps.Geocoder(),
		autocompleteOK: window.google.maps.places.PlacesServiceStatus.OK
	})

	const [ formDisabled, setFormDisabled ] = useState((localStorage.getItem('sia-map') && localStorage.getItem('sia-map') === 'true') ? true : false);
	const [ form, setForm ] = useState({
		optIn: true,
		first_name: '',
		last_name: '', 
		email: ''
	});

	const [ state, setState ] = useState(initialState);

	const [address, setAddress] = useState('');
	const [ selectedAddress, setSelectedAddress ] = useState('');
	const [ selectedCSZ, setSelectedCSZ ] = useState('');

	useEffect(() => {
		if (services.autocompleteService) {
		  // Re-create getPredictions callback or take necessary actions
		  // console.log('autocompleteService is now set');
		}
	}, [services.autocompleteService]);

	const handlePredictions = useCallback((predictions, status) => {
		if (status !== services.autocompleteOK) {
			setState(initialState);
			return;
		}
		const formattedSuggestion = (text) => ({
			name: text.main_text,
			body: text.secondary_text
		})
		setState(prev => ({
			...prev,
			items: predictions.map((place, index) => ({
				index,
				description: place.description,
				placeId: place.place_id,
				raw: place,
				name: formattedSuggestion(place.structured_formatting)
			}))
		}))
		
	}, [services.autocompleteOK])

	const getPredictions = useCallback((value) => {
		if (!services.autocompleteService) {
			console.warn('autocompleteService is not ready yet');
			return;
		}
		if (value && services.autocompleteService) {
			try {
				services.autocompleteService.getPlacePredictions(
					{ input: value }, 
					handlePredictions
				);
			} catch (e) {
				console.log('getPredictions error:', e.message)
			}
		}
	}, [ services.autocompleteService, handlePredictions ]);

	const debouncedFetchSuggestions = useCallback(
		debounce(getPredictions, 500)
	, []);

	const handleAddressChange = useCallback((e) => {
		e.preventDefault();

		const { value } = e.target;
		setAddress(value)

		if (value.length > 2) {
			debouncedFetchSuggestions(value)
		} else {
			setState(prev => ({ ...prev, items: [] }))
		}

	}, [ debouncedFetchSuggestions, getPredictions ]);

	const handleSuggestionClick = (suggestion) => {
		setSelectedAddress(suggestion.description);
		setSelectedCSZ(suggestion.raw.structured_formatting.secondary_text);
		setState(initialState)
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
        
		setIsSubmitting(true)

		const { first_name, last_name, email, optIn } = form;
        try {
            // Get user IP address (requires an external API, such as ipify)
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const userIp = data?.ip ?? '';

            // Get user agent
            const userAgent = navigator.userAgent;

            // Check if the IP address has already submitted
			// Suspend checks on IP address, to allow multiple entries from the same (cases where multiple users in same building?)
            /*
			const { data: existingSubmission, error } = await supabase
                .from('csz_lat_lng')
                .select('*')
                .eq('ip_address', userIp)
                .single();
			

            if (existingSubmission) {
                alert('You have already submitted an address.');
                return;
            }
			*/
			
			// Geocode the address to get latitude and longitude
			services.geocoder.geocode({ address: selectedCSZ }, async (results, status) => {
				if (status === 'OK') {
					const lat = results[0].geometry.location.lat();
					const lng = results[0].geometry.location.lng();

					// Insert data into Supabase
					const { insertError } = await supabase
						.from('address_submissions')
						.insert([{ 
							ip_address: userIp ?? '',
							user_agent: userAgent,
							
							first_name,
							last_name,
							email,

							entry: selectedAddress,
							geo_address: selectedCSZ,
							opt_in: optIn,

							latitude: lat,
							longitude: lng
						}]);

					if (insertError) {
						throw new Error('Error logging submission.');
					}
					
					localStorage.setItem('sia-map', 'true');

					setFormDisabled(true);
					setIsSubmitting(false)

				} else {
					console.warn('Geocode was not successful for the following reason: ' + status);
					setIsSubmitting(false)
				}
			});

        } catch (error) {
            alert(error.message);
        }
    };

	/*
    const geocodeAddress = (address) => {
        if (geocoder) {
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === 'OK') {
                    map.setCenter(results[0].geometry.location);
                    map.setZoom(15);
                    new window.google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location,
                    });
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
    };
	*/

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		
		setForm(prevForm => ({
			...prevForm,
			[name]: type === "checkbox" ? (checked ? true : false) : value
		}))
	}

	const missingFields = () => {
		const { first_name, last_name, email } = form;

		if ( first_name === '' || last_name === '' || email === '' || selectedAddress === '' ) 
			return true; 
		
		return false; // all fields are set -> field should NOT be disabled
	}

	const { first_name, last_name, email } = form;
	const { items, active } = state;

    return (
        <div className="container mt-5">
			{formDisabled ? (
				<div className="text-center">
					<h3 className="mb-4">Thank you for your Submission!</h3>
					<div>
						<img src="images/google-maps-icon.png" alt="google maps icon" />
					</div>
					<Link to="/map" className="btn btn-primary mt-5">Click Here to see Map</Link>
				</div>
			) : (
				<form onSubmit={handleSubmit} className="mb-4">
					<h3 className="mb-4 px-0 px-sm-5 px-lg-3 text-center text-danger fw-bold">Put Yourself on the Misfit Map.</h3>
					<p className="border border-success text-center rounded p-3">
						Add your candy cane to Candy Cane Lane and receive a personalized e-postcard from The Misfits! Plus, your (general) location will be featured on our global Misfit map for fans around the world to see
					</p>
					
					<div className="form-body mb-3">
						<div className="me-0 me-sm-2">
							<label htmlFor="first_name" className="form-label">
								First Name
								{form?.first_name ? (
									<span className="badge ms-2 fs-9">
										<FontAwesomeIcon icon={faCheckCircle} className="text-success fw-bold" />
									</span>
								) : (
									<span className="badge rounded-pill text-bg-success text-white ms-2 fs-9">Required</span>
								)}
							</label>
							<input type="text" id="first_name" name="first_name" required className="form-control" autoComplete='false' placeholder='First Name' value={first_name} onChange={handleChange} disabled={isSubmitting} />
						</div>
						<div className="mt-3 mt-sm-0">
							<label htmlFor="last_name" className="form-label">
								Last Name
								{form?.last_name ? (
									<span className="badge ms-2 fs-9">
										<FontAwesomeIcon icon={faCheckCircle} className="text-success fw-bold" />
									</span>
								) : (
									<span className="badge rounded-pill text-bg-success text-white ms-2 fs-9">Required</span>
								)}
							</label>
							<input type="text" id="last_name" name="last_name" required className="form-control" placeholder='Last Name' value={last_name} onChange={handleChange} disabled={isSubmitting} />
						</div>
					</div>

					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							Email Address
							{form?.email ? (
								<span className="badge ms-2 fs-9">
									<FontAwesomeIcon icon={faCheckCircle} className="text-success fw-bold" />
								</span>
							) : (
								<span className="badge rounded-pill text-bg-success text-white ms-2">Required</span>
							)}
						</label>
						<input type="email" id="email" name="email" required className="form-control" placeholder='name@example.com' value={email} onChange={handleChange} disabled={isSubmitting} />
					</div>

					<div className="mb-3">

						{selectedAddress ? (
							<div className="position-relative border rounded border-success py-2 px-3">
								<label className="text-success d-block">
									<FontAwesomeIcon icon={faCheckCircle} className="fw-bold me-1" />
									Selected Address ( <span className="text-danger cursor-pointer" onClick={() => setSelectedAddress('')}>change</span> )
								</label>
								{selectedAddress}
							</div>
						) : (
							<>
								<label htmlFor="address-input" className="form-label">Mailing Address:</label>
								{(selectedAddress || address) ? (
									<span className="badge ms-2 fs-9">
										<FontAwesomeIcon icon={faCheckCircle} className="text-success fw-bold" />
									</span>
								) : (
									<span className="badge rounded-pill text-bg-success text-white ms-2 fs-9">Required</span>
								)}
								
								<input
									type="text"
									id="address-input"
									value={address}
									onChange={handleAddressChange}
									placeholder="Type an address..."
									className="form-control"
								/>
								{items.length > 0 && (
									<ul className="suggestions-list">
										{items.map((suggestion) => (
											<li
												key={suggestion.index}
												onClick={() => handleSuggestionClick(suggestion)}
											>
												{suggestion.description}
											</li>
										))}
									</ul>
								)}
							</>
						)}
						
					</div>
					
					{/* <div className="mb-3">
						<label htmlFor="address" className="form-label">Address:</label>
						<input type="text" id="address" name="address" required className="form-control" />
					</div>
					<div className="mb-3">
						<label htmlFor="city" className="form-label">City:</label>
						<input type="text" id="city" name="city" required className="form-control" />
					</div>
					<div className="mb-3">
						<label htmlFor="state" className="form-label">State:</label>
						<input type="text" id="state" name="state" required className="form-control" />
					</div>
					<div className="mb-3">
						<label htmlFor="zip" className="form-label">Zip Code:</label>
						<input type="text" id="zip" name="zip" required className="form-control" />
					</div>
					*/}
					<div className="mb-3">
						<input type="checkbox" name="optIn" id="optIn" value="Y" className="mx-3" checked={form?.optIn} onChange={handleChange} />
						<label htmlFor="optIn" className="form-label">Recieve emails from The Misfits!</label>
					</div>

					<button type="submit" className={"btn w-100" +(isSubmitting || missingFields ? ' btn-secondary' : ' btn-success')} disabled={isSubmitting}>
						{isSubmitting ? 'Submitting Entry' : 'Submit'}
					</button>
					<div className="text-center mt-3">
						<a href="https://www.atlanticrecords.com/terms-of-use" target='_blank' rel="noreferrer">Terms of Use</a> | <a href="https://privacy.wmg.com/atlantic/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</a>
					</div>

					<p className="text-center mt-5">
						<Link to="map" target="_blank" rel="noreferrer" className={"text-success text-decoration-none"}>
							View the Map <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
						</Link>
					</p>
				</form>
			)}
            {/* <div ref={mapElement} style={{ height: '400px', width: '100%' }} className="mt-4"></div> */}
        </div>
    );
};

export default AddressFormWithMap;
