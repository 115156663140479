import React, { useCallback } from 'react';
import {
	AdvancedMarker,
	AdvancedMarkerAnchorPoint,
	useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';

// import {CandyCaneSvg} from './candy-cane-svg'
import CandyCaneSvg from '../assets/CandyCaneSVG.svg'

export const FeatureMarker = ({ position, featureId, onMarkerClick }) => {
	const [markerRef, marker] = useAdvancedMarkerRef();
	const handleClick = useCallback(
		() => onMarkerClick && onMarkerClick(marker, featureId),
		[onMarkerClick, marker, featureId]
	);

	return (
		<AdvancedMarker
			ref={markerRef}
			position={position}
			onClick={handleClick}
			anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
			className={'marker feature'}>
			<img src={CandyCaneSvg} alt="Misfit Marker" />
		</AdvancedMarker>
	);
};
