import React, { useState, useEffect } from 'react';

import { Modal } from 'react-bootstrap';

import KuulaProject from '../components/KuulaProject';
import PDFDownload from '../components/PDFDownload';
import AddressFormWithMap from '../components/AddressFormWithMap';

import '../Home.css';

import modalBackground from '../assets/PDF-background.png'

import topLeft from '../assets/SIAPDF_0000_Layer-4.png'
import topRight from '../assets/SIAPDF_0001_Layer-3.png'
import bottomLeft from '../assets/SIAPDF_0002_Layer-2.png'
import bottomRight from '../assets/SIAPDF_0003_Layer-1.png'

// import Sia from '../assets/Misfits (Sia).pdf'
// import Puppy from '../assets/Misfits (Puppy).pdf'
// import Snowman from '../assets/Misfits (Snowman).pdf'
// import Elf from '../assets/Misfits (Elf).pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

const file_downloads = [
	{
		src: topLeft,
		alt: 'Misfits (Sia)',
		// file_loc: Sia
		file_loc: '/files/Misfits (Sia).pdf'
	},
	{
		src: topRight,
		alt: 'Misfits (Puppy)',
		// file_loc: Puppy
		file_loc: '/files/Misfits (Puppy).pdf'
	},
	{
		src: bottomLeft,
		alt: 'Misfits (Snowman)',
		// file_loc: Snowman
		file_loc: '/files/Misfits (Snowman).pdf'
	},
	{
		src: bottomRight,
		alt: 'Misfits (Elf)',
		// file_loc: Elf
		file_loc: '/files/Misfits (Elf).pdf'
	}
]

export default function Home() {

	const [ hotspot, setSelectedHotspot ] = useState('');

	const [ showPDFModal, setShowPDFModal ] = useState(false);
	const [ showForm, setShowForm ] = useState(false);
	
	function handleClosePDFModal() {
		setShowPDFModal(false);
		setSelectedHotspot('')
	}
  
	useEffect(() => {

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			// console.log('frameloaded', e.frame)
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			setSelectedHotspot(e.data.name);
		});

	}, []);

	useEffect(() => {
		// form hotspot selected in scene
		if ( hotspot === 'SF00' ) {
			setShowPDFModal(true)
		}

		if ( hotspot === 'SF01' ) {
			setShowForm(true)
		}
	}, [ hotspot ])

	const handleCloseForm = () => {
		setShowForm(false);
		setSelectedHotspot(null);
	}

	return (
		<div className="App">
			<Modal centered show={showPDFModal} fullscreen={'sm-down'} onHide={handleClosePDFModal} dialogClassName='custom-dialog'>
				<Modal.Body className="m-0 p-0">
					<div className="body-container">
						
						<FontAwesomeIcon icon={faTimesCircle} onClick={handleClosePDFModal}
							className="bg-danger rounded-circle text-white fa-2x position-absolute top-0 end-0" style={{ margin: '-10px -10px 0 0', cursor: 'pointer' }} />

						<h2>Take the Misfits on Your Holiday Adventures!</h2>
						<div className="grid-container">
							{file_downloads.map((pdf, idx) => (
								<div key={idx} className="grid-item"><PDFDownload file={pdf} /></div>
							))}
						</div>
						<div className='text-center'>
							<p>
								<span className="text-danger fw-bold d-block">Click the images above</span> to download a PDF of your favorite Misfit and cut along the dotted line. Post photos of you with your misfit on their adventures using 
								your favorite track from Everyday is Christmas and use #SiaMisfitChristmas on your socials
							</p>
							<p>
								You can also send your misfit to friends and family and invite them to send the misfit back with a tale of their adventures!
							</p>
							<p>
								Follow #SiaMisfitChristmas to see the misfits all over the world!!
							</p>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={showForm} onHide={handleCloseForm}>
				<Modal.Body>
					<AddressFormWithMap />
				</Modal.Body>
			</Modal>

			<KuulaProject />
		</div>
	)
}