import React, { useEffect } from 'react';

import './KuulaStyle.css'

export default function KuulaProject(props) {

	useEffect(() => {
	// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				title="sia-merry-misfit-land"
				allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullScreen scrolling="no" 
				src="https://tours.mindpower.dev/share/collection/7Zbmz?logo=-1&info=0&fs=0&vr=0&sd=1&thumbs=-1&margin=20"></iframe>
		</div>
	)
}