import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { createClient } from '@supabase/supabase-js';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { Map } from '@vis.gl/react-google-maps';
import { ClusteredMarkers } from '../components/clustered-markers';

import './SiaMap.css';

import Footer from '../components/Footer';

import WreathSvg from '../assets/map-border.svg'
import castles from '../assets/castles.json'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function SiaMap() {

	const [ geojson, setGeojson ] = useState(null);
	const [ numClusters, setNumClusters ] = useState(0);

	// TODO: create the city/state/zip view (grouped by, only unique values, etc.)
    useEffect(() => {

		const loadMarkerGeojson = async () => {

			const { data: subs, error } = await supabase
					.from('geocoords')
					.select('*');
			
			if ( subs ) {
				var formattedGeojson = subs.map((marker, idx) => (
					{
						"type": "Feature",
						"id": idx,
						"geometry": {
						  "type": "Point",
						  "coordinates": [marker.longitude, marker.latitude]
						},
						"properties": {
						  	"name": 'marker'
							// "name": marker.city + ', ' + (marker?.state ? marker.state : marker.country)
						}
					}
				))
				setGeojson(
					{
						"type": "FeatureCollection",
						"features": [
							...formattedGeojson
						]
					}
				);
			}
		}
		loadMarkerGeojson();
		
		// setGeojson(castles);

    }, []);

	const position = {
		lat: 39.82849, lng: -98.5788
	};

	// console.log('geojson', geojson)

	return (
		<div className="mapPage">
			<Container className="mapContainer">

				<Row className="py-5">
					<Col xs={12} sm={4} className="backLink">
						<Link to="/" className="me-5">
							<Image src="/images/Back.png" alt="Back to Merry Misfit Land" fluid />
						</Link>
					</Col>
					<Col xs={12} sm={8} className="d-flex justify-content-start align-items-center">
						<Image src="/images/Sia-Sign.png" alt="Sia Sign" fluid />
					</Col>
				</Row>
				<Row className="pb-5">
					<Col>
						{/* <div ref={mapElement} style={{ height: '400px', width: '100%' }} ></div> */}
						{/* <div class="wreath-container"> */}
							<Map
								mapId={'8707b944bd888bde'}
								defaultCenter={position}
								defaultZoom={3}
								gestureHandling={'greedy'}
								disableDefaultUI
								className={'custom-marker-clustering-map border border-dark shadow'}>
								{geojson && (
									<ClusteredMarkers
										geojson={geojson}
										setNumClusters={setNumClusters} />
								)}
							</Map>
							{/* <img src={WreathSvg} alt="wreath" />
						</div> */}
					</Col>
				</Row>

			</Container>
			<Footer />
		</div>
	);
}

export default SiaMap;