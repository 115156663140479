import { useState } from "react";

import Image from 'react-bootstrap/Image'

const PDFDownload = ({ file }) => {
	const [ clicked, setClicked ] = useState(false);
	
	const download = () => {
		setClicked(true);

		// Create an anchor element programmatically
		const link = document.createElement('a');
		link.href = file.file_loc;
		link.download = file.alt;
		link.click();
	}

	return (
		<Image src={file.src} 
			alt={file.alt} 
			className={"download-file" +(clicked ? ' clicked' : ' unclicked')} 
			onClick={download} title="Click to Download"
			fluid
		/>
	)
}
export default PDFDownload;