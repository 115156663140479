import Supercluster from 'supercluster';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useMapViewport } from './use-map-viewport';

export function useSupercluster(geojson, superclusterOptions) {
	// create the clusterer and keep it
	const clusterer = useMemo(() => {
		return new Supercluster(superclusterOptions);
	}, [superclusterOptions]);

	// version-number for the data loaded into the clusterer
	// (this is needed to trigger updating the clusters when data was changed)
	const [version, dataWasUpdated] = useReducer((x) => x + 1, 0);

	// when data changes, load it into the clusterer
	useEffect(() => {
		clusterer.load(geojson.features);
		dataWasUpdated();
	}, [clusterer, geojson]);

	// get bounding-box and zoom level from the map
	const { bbox, zoom } = useMapViewport({ padding: 100 });

	// retrieve the clusters within the current viewport
	const clusters = useMemo(() => {
		// don't try to read clusters before data was loaded into the clusterer (version===0),
		// otherwise getClusters will crash
		if (!clusterer || version === 0) return [];

		return clusterer.getClusters(bbox, zoom);
	}, [version, clusterer, bbox, zoom]);

	// create callbacks to expose supercluster functionality outside of this hook
	const getChildren = useCallback(
		(clusterId) => clusterer.getChildren(clusterId),
		[clusterer]
	);

	// note: here, the paging that would be possible is disabled; we found this
	// has no significant performance impact when it's just used in a click event handler.
	const getLeaves = useCallback(
		(clusterId) => clusterer.getLeaves(clusterId, Infinity),
		[clusterer]
	);

	const getClusterExpansionZoom = useCallback(
		(clusterId) => clusterer.getClusterExpansionZoom(clusterId),
		[clusterer]
	);

	return {
		clusters,
		getChildren,
		getLeaves,
		getClusterExpansionZoom,
	};
}