import React, { useCallback } from 'react';
import {
	AdvancedMarker,
	AdvancedMarkerAnchorPoint,
	useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

// import {CandyCaneGroupSvg} from './candy-cane-svg'
import CandyCaneGroupSvg from '../assets/GreenPresent.svg'

export const FeaturesClusterMarker = ({
	position,
	size,
	sizeAsText,
	onMarkerClick,
	clusterId,
}) => {
	const [markerRef, marker] = useAdvancedMarkerRef();
	const handleClick = useCallback(
		() => onMarkerClick && onMarkerClick(marker, clusterId),
		[onMarkerClick, marker, clusterId]
	);
	const markerSize = Math.floor(48 + Math.sqrt(size) * 2);
	return (
		<AdvancedMarker
			ref={markerRef}
			position={position}
			zIndex={size}
			onClick={handleClick}
			className={'marker cluster'}
			style={{ width: markerSize, height: markerSize }}
			anchorPoint={AdvancedMarkerAnchorPoint.CENTER}>
			<img src={CandyCaneGroupSvg} alt="Misfit Cluster" />
			<span>{sizeAsText}</span>
		</AdvancedMarker>
	);
};
