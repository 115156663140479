
import './Footer.css';

function Footer() {
	return (
		<footer className="pb-3" style={{ color: '#444' }}>
			{/*
			<Row>
				<Col style={{ textAlign: 'left' }}>
					&copy; 2023 The Shredderz<br />
					<Link to="/terms" style={{ marginLeft: 0 }}>Terms of Use</Link> | 
					<Link to="/refund">No Refund Policy</Link> | 
					<Link to="/faq">What's an NFT?</Link>
				</Col>
				<Col style={{ textAlign: 'right' }}>
					<a href="https://facebook.com" target="_blank" rel="noreferrer">
						Facebook
					</a> | 
					<a href="https://instagram.com" target="_blank" rel="noreferrer">Instagram</a> | 
					<a href="https://twitter.com" target="_blank" rel="noreferrer">Twitter</a> |
					<a href="https://foundation.app" target="_blank" rel="noreferrer">Foundation</a> | 
					<a href="https://superrare.io" target="_blank" rel="noreferrer">SuperRare</a> | 
					<a href="https://opensea.io" target="_blank" rel="noreferrer">OpenSea</a>
				</Col>
			</Row>
			*/}
			<p>
				&copy; 2024 Sia
				{/*
				<span style={{ fontSize: '.75rem', color: '#222' }}>
					Powered by <a href="https://mindpower.dev" target="_blank" rel="noreferrer" className="m-0 p-0" style={{ color: '#222' }}>Mind Power</a>
				</span>
				*/}
			</p>
		</footer>
	);
}

export default Footer;


