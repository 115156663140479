import React, { useCallback, useEffect } from 'react';
import { FeaturesClusterMarker } from './features-cluster-marker';
import { FeatureMarker } from './feature-marker';
import { useSupercluster } from '../hooks/use-supercluster';

const superclusterOptions = {
  extent: 256,
  radius: 80,
  maxZoom: 12,
};

export const ClusteredMarkers = ({ geojson, setNumClusters, setInfowindowData }) => {
	const { clusters, getLeaves } = useSupercluster(geojson, superclusterOptions);

	useEffect(() => {
		setNumClusters(clusters.length);
	}, [setNumClusters, clusters.length]);

	/*
	const handleClusterClick = useCallback((marker, clusterId) => {
		const leaves = getLeaves(clusterId);
			setInfowindowData({ anchor: marker, features: leaves });
		},
		[getLeaves, setInfowindowData]
	);

	const handleMarkerClick = useCallback(
		(marker, featureId) => {
		const feature = clusters.find((feat) => feat.id === featureId);
			setInfowindowData({ anchor: marker, features: [feature] });
		},
		[clusters, setInfowindowData]
	);
	*/

	return (
		<>
		{clusters.map((feature) => {
			const [lng, lat] = feature.geometry.coordinates;
			const clusterProperties = feature.properties;
			const isCluster = clusterProperties.cluster;

			return isCluster ? (
				<FeaturesClusterMarker
					key={feature.id}
					clusterId={clusterProperties.cluster_id}
					position={{ lat, lng }}
					size={clusterProperties.point_count}
					sizeAsText={String(clusterProperties.point_count_abbreviated)}
					// onMarkerClick={handleClusterClick}
				/>
			) : (
				<FeatureMarker
					key={feature.id}
					featureId={feature.id}
					position={{ lat, lng }}
					// onMarkerClick={handleMarkerClick}
				/>
			);
		})}
		</>
	);
};